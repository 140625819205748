import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";

import img from "../../../../assets/img/tab-block.jpg";

const missiontab = [
  {
    icon: "fas fa-check",
    header: "Start-Up",
    text:
      "Let us provide the services you desire on an as needed basis.  Our consulting uniqueness incorporates life coaching principles coupled with project management methodologies delivering what YOU need.",
  },
  {
    icon: "fas fa-check",
    header: "Small Business",
    text:
      "Rest assured: we deliver the highest quality standards of service that is cost effective coupled with high performance.",
  },
  {
    icon: "fas fa-check",
    header: "Established Entity",
    text:
      "Aglosi Inc. welcomes the opportunity to team up with businesses with complementary corporate values and are committed to delivering quality services that will meet or exceed the expectations of our clients.!",
  },
];
const historytab = [
  {
    icon: "fas fa-check",
    header: "Virtual",
    text:
      "We offer live online group training sessions with a minimum of 5 attendees. A benefit of virtual training is it will not get rescheduled due to weather conditions, traffic congestion or other unforeseen issues.",
  },
  {
    icon: "fas fa-check",
    header: "In-person",
    text:
      "We will develop live tailored professional development for a minimum of 5 attendees.  PMP Exam training or other consecutive training must be scheduled during any four consecutive days of your choosing.",
  },
  {
    icon: "fas fa-check",
    header: "Joint Ventures",
    text:
      "We are also available for opportunities to team up or engage in joint ventures etc. Contact us for further details!",
  },
];
const businessgoalstab = [
  {
    icon: "fas fa-check",
    header: "Individual Coaching",
    text:
      "We will emphasize the unlimited opportunities that the client may wish to change or improve upon to achieve your goal. Individual coaching is performed in person, over the phone or Skype.",
  },
  {
    icon: "fas fa-check",
    header: "Group Coaching",
    text:
      "The clients range from a variety of disciplines, departments or organizations.  Group coaching is performed in person (local venue), conference call or Skype.",
  },
  {
    icon: "fas fa-check",
    header: "Team Coaching",
    text:
      "The team coaching often includes managers or some form of hierarchy, members on the same project, team, department etc. Team coaching is performed on site or local ven",
  },
];

class Advancedtab extends Component {
  render() {
    return (
      <section className="advanced-tab section-gap">
        <div className="container">
          {/* Tabs Buttons */}
          <div className="tab-buttons">
            <Tab.Container defaultActiveKey="mission">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="mission">Business Guidance</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="businessgoals">
                    Coaching Services
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history">Development Training</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="mission">
                  <div className="tab-text-block left-image with-left-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10">
                        <div className="block-image">
                          <img src={img} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10">
                        <div className="block-text">
                          <h2 className="title">
                            Professional Business Guidance Agency
                          </h2>
                          <p>
                            Our business matchmaking fitness solutions
                            incorporates customized strategic methodologies
                            designed specifically for each client. We listen,
                            gather data, and analyze in order to deliver the
                            best value for our clients.
                          </p>
                          <ul>
                            {missiontab.map((item, i) => (
                              <div>
                                <li key={i}>
                                  <h4>{item.header}</h4>
                                </li>
                                <li key={i}>{item.text}</li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="history">
                  <div className="tab-text-block right-image with-right-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10 order-2 order-lg-1">
                        <div className="block-text">
                          <h2 className="title">
                            Tailored Development Training
                          </h2>
                          <p>
                            We will deliver tailored professional development
                            training at your location or a location in your area
                            with a minimum of 5 attendees. PMP Exam training or
                            other consecutive training must be scheduled during
                            any four consecutive days of your choosing.
                          </p>
                          <ul>
                            {historytab.map((item, i) => (
                              <div>
                                <li key={i}>
                                  <li key={i}>
                                    <h4>{item.header}</h4>
                                  </li>
                                </li>
                                <li key={i}>{item.text}</li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div className="block-image">
                          <img src={img} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="businessgoals">
                  <div className="tab-text-block left-image with-left-circle">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-6 col-md-10">
                        <div className="block-image">
                          <img src={img} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-10">
                        <div className="block-text">
                          <h2 className="title">
                            Professional Coaching Services
                          </h2>
                          <p>
                            We offer executive group or individual (one-on-one)
                            coaching services. Constact us today and select your
                            coaching option in the drop down menu so we can make
                            the appropriate arrangements to accommodate your
                            needs.
                          </p>
                          <ul>
                            {businessgoalstab.map((item, i) => (
                              <div>
                                <li key={i}>
                                  <h4>{item.header}</h4>
                                </li>
                                <li key={i}>{item.text}</li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    );
  }
}

export default Advancedtab;
