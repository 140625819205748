import React from "react";
import Firebase from "firebase/app";
import { FirestoreProvider } from "react-firestore";
import ReactGA from "react-ga";
import { BrowserRouter, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "firebaseui/dist/firebaseui.css";

import Routes from "./Routes";
import "../styles/global";

class App extends React.Component {
  render() {
    return (
      <FirestoreProvider firebase={Firebase}>
        <BrowserRouter>
          <React.Fragment>
            <Route path="/" component={ScrollToTop} />
            <Route path="/" component={Analytics} />
            <Routes />
            <Toaster />
          </React.Fragment>
        </BrowserRouter>
      </FirestoreProvider>
    );
  }
}

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248
const Analytics = ({ location }) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);
  return null;
};

export default App;
