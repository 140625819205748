import React, { Component, Fragment } from "react";
import About from "./About";
import Banner from "./Banner";
import Contact from "../../pages/Contact";
import Service from "./Service";
import Skills from "./Skills";
import Testimonials from "./Testimonials";
import Video from "./Video";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        <About />
        <Service />
        <Video />
        <Skills />
        <Testimonials />
        <Contact />
      </Fragment>
    );
  }
}

export default Content;
