import React, { Component } from "react";
import { Link } from "react-router-dom";

import illustration from "../../../../assets/img/illustration/01.png";
class About extends Component {
  render() {
    return (
      <section className="about-section about-illustration-img section-gap">
        <div className="container">
          <div className="illustration-img">
            <img src={illustration} alt="" />
          </div>
          <div className="row no-gutters justify-content-lg-end justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="about-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">About Us</span>
                  <h2 className="title">
                    Professional Business <br /> Guidance Agency{" "}
                  </h2>
                </div>
                <p className="mb-25">
                  We are a Woman Owned, Service Disabled Veteran firm that takes
                  a unique approach to conquering your unique challenges. We
                  don’t believe in the adage that “one hat fits all”.
                </p>
                <p>
                  Our business matchmaking fitness solutions incorporate custom
                  strategies designed for each client. We listen, gather data,
                  and analyze in order to deliver the best value for our
                  clients.
                </p>
                <p>Our tailored services include:</p>
                <ul className="about-list">
                  <li>
                    {" "}
                    <i className="far fa-check" /> Consulting in Project
                    Management
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Team Coaching or Personalized
                    Life Coaching
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Tailored Professional
                    Development Training
                  </li>
                </ul>
                <Link to="/contact" className="main-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
