import React, { Component } from "react";
import { Link } from "react-router-dom";

class Memberdetail extends Component {
  render() {
    return (
      <section className="member-details-wrapper section-gap-top">
        <div className="container">
          <div className="member-details">
            <div className="member-desc">
              <h3 className="name">John N. Holmes</h3>
              <span className="pro">Business Developer</span>
              <p>
                Sedut perspiciatis unde omnis iste natus error sit voluptatem
                accusant doloremque laudantium totam rem aperiam eaquipsa quae
                ab illo inventore veritatis
              </p>
              <p>
                Quasi architecto beatae vitae dicta sunt explica boemo enim
                ipsam volupt
              </p>
            </div>
            <div className="member-contact-info">
              <h3 className="title">Get In Touch</h3>
              <span className="title-tag">Contact Me</span>
              <ul className="contact-info">
                <li>
                  <i className="far fa-phone" />{" "}
                  <strong>+012 (345) 6789</strong>
                </li>
                <li>
                  <Link to="#">
                    <i className="far fa-envelope" /> support@gmail.com
                  </Link>
                </li>
                <li>
                  <i className="far fa-map-marker-alt" /> Broklyn Street USA
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Memberdetail;
