import React, { Component } from "react";

import processbg1 from "../../../../assets/img/video-bg/01.jpg";

const processlist = [
  {
    icon: "fal fa-coffee",
    number: "01",
    title: "Initiation Phase",
    text:
      "Save time and money by letting us identify if your project is worth pursuing or should it be a pet project. Perhaps it’s a project on resuscitation (troubled project) that needs to be rescued.",
  },
  {
    icon: "fal fa-coffee",
    number: "02",
    title: "Planning Phase",
    text:
      "This is one of the most challenging phases for a project; we will ensure that you deliver the project on time and within budget.",
  },
  {
    icon: "fal fa-coffee",
    number: "03",
    title: "Organizing Chaos",
    text:
      "Perhaps you could use a consultant to assist you from being overwhelmed or stressing out with your strategies.",
  },
  {
    icon: "fal fa-coffee",
    number: "04",
    title: "Consulting Services",
    text:
      "We can always assist you in customizing a tailored strategic process or a focused training that is specific to your needs.",
  },
];

class Workingprocess extends Component {
  render() {
    return (
      <section className="working-process-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10 order-lg-1 order-2">
              <div className="process-text">
                {/* Section Title */}
                <div className="section-title left-border mb-30">
                  <span className="title-tag">Working Process</span>
                  <h2 className="title">How We Work</h2>
                </div>
                <p>
                  Our experienced staff is readily available to serve your needs
                  in your office, virtually or over coffee. You decide! We will
                  work with your ideas to create best case scenarios to make an
                  informative decision.
                </p>
                {/* process-loop */}
                <div className="process-loop">
                  {processlist.map((item, i) => (
                    <div
                      key={i}
                      className="single-process wow fadeInUp"
                      data-wow-duration="1500ms"
                      data-wow-delay="400ms"
                    >
                      <div className="icon">
                        <i className={item.icon} />
                        <span>{item.number}</span>
                      </div>
                      <div className="content">
                        <h4>{item.title}</h4>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10 order-lg-2 order-1">
              <div
                className="process-video bg-img-c"
                style={{ backgroundImage: "url(" + processbg1 + ")" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="working-circle" />
      </section>
    );
  }
}

export default Workingprocess;
