import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import img1 from "../../../../assets/img/banner/01.jpg";
import img2 from "../../../../assets/img/banner/02.jpg";
import img3 from "../../../../assets/img/banner/03.jpg";

const bannerpost = [
  {
    img: img1,
    tag: "business consulting",
    title: "Your Vision is Our Mission",
    btntext: "Contact Us Today",
  },
  {
    img: img2,
    tag: "development training",
    title: "Professional Business Guidance Agency",
    btntext: "Contact Us Today",
  },
  {
    img: img3,
    tag: "team & personal coaching",
    title: "Matchmaking Small Businesses & Strategic Fitness",
    btntext: "Contact Us Today",
  },
];

class Banner extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      arrows: false,
      fade: false,
      dots: false,
      swipe: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    return (
      <section className="banner-section">
        <Slider className="banner-slider" id="bannerSlider" {...settings}>
          {bannerpost.map((item, i) => (
            <div key={i}>
              <div
                className="single-banner"
                style={{ backgroundImage: "url(" + item.img + ")" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="banner-content">
                        <span
                          className="promo-text"
                          data-animation="fadeInDown"
                          data-delay="0.8s"
                        >
                          {item.tag}
                        </span>
                        <h1 data-animation="fadeInUp" data-delay="1s">
                          {item.title}
                        </h1>
                        <ul className="btn-wrap">
                          <li data-animation="fadeInLeft" data-delay="1.2s">
                            <Link to="/contact" className="main-btn main-btn-4">
                              {item.btntext}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-shapes">
                  <div className="one" />
                  <div className="two" />
                  <div className="three" />
                  <div className="four" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="search-wrap">
          <Link to="#" className="search-icon">
            <i className="far fa-search" />
          </Link>
        </div>
      </section>
    );
  }
}

export default Banner;
