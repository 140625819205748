import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import emailjs from "emailjs-com";
import toast from "react-hot-toast";
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      first: "",
      last: "",
      email: "",
      time: "",
      message: "",
      need: "",
      status: "",
      disabled_veteran: false,
      veteran_owned: false,
      "8(a)_business": false,
      historically_underutilized_business: false,
      business_strategy: false,
      woman_owned_small_business: false,
      small_disadvantaged_business: false,
      minority_owned_small_business: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    target.type === "checkbox"
      ? this.setState({ [target.id]: !this.state[target.id] })
      : target.id === "need"
      ? this.setState({
          [target.id]:
            value === 0
              ? "Business Matchmaking Fitness"
              : value === 1
              ? "Life Coacing Services"
              : "Professional Development training",
        })
      : target.id === "status"
      ? this.setState({
          [target.id]:
            value === 0
              ? "LLC"
              : value === 1
              ? "Partnership"
              : value === 2
              ? "Corporation"
              : "In-progress",
        })
      : this.setState({ [name]: value });
  }

  sendMessage(event) {
    event.preventDefault();
    if (
      !this.state.message.length ||
      !this.state.first.length ||
      !this.state.last.length ||
      !this.state.email.length ||
      !this.state.title.length
    ) {
      toast.error("All fields are required");
      return;
    }

    const templateParams = {
      from_name:
        this.state.title + " " + this.state.first + " " + this.state.last,
      from_email: this.state.email,
      to_name: "Aglosi, Inc.",
      time: this.state.time,
      status: this.state.status,
      need: this.state.need,
      message: this.state.message,
      disabled_veteran: this.state.disabled_veteran,
      veteran_owned: this.state.veteran_owned,
      a: this.state["8(a)_business"],
      underutilized: this.state.historically_underutilized_business,
      strategy: this.state.business_strategy,
      woman: this.state.woman_owned_small_business,
      disadvantaged: this.state.small_disadvantaged_business,
      minority: this.state.minority_owned_small_business,
      confirmation: this.state.confirmation,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        function () {
          toast.success("Message sent! We will be in contact shortly");
        },
        function () {
          toast.error(
            "Your message was not able to be sent. Please try again later"
          );
        }
      );
    this.setState({
      first: "",
      last: "",
      title: "",
      email: "",
      time: "",
      message: "",
      need: "",
      status: "",
      disabled_veteran: false,
      veteran_owned: false,
      "8(a)_business": false,
      historically_underutilized_business: false,
      business_strategy: false,
      woman_owned_small_business: false,
      small_disadvantaged_business: false,
      minority_owned_small_business: false,
      confirmation: false,
    });
  }

  render() {
    return (
      <section
        className="contact-section contact-page section-gap-top"
        ref={(section) => {
          this.contact = section;
        }}
      >
        <div className="container">
          <div className="contact-form grey-bg">
            <div className="row no-gutters justify-content-center">
              <div className="col-10">
                <div className="section-title text-center mb-40">
                  <h2 className="title">Contact Us Today</h2>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Title"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          id="first"
                          name="first"
                          placeholder="First Name"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          id="last"
                          name="last"
                          placeholder="Last Name"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Your Email Address"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <input
                          type="time"
                          id="time"
                          name="time"
                          placeholder="Best time to contact you"
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <select
                          type="dropdown"
                          id="need"
                          name="need"
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option disabled hidden selected>
                            Select Your Need
                          </option>
                          <option value={0}>
                            Business Matchmaking Fitness
                          </option>
                          <option value={1}>Life Coaching Services</option>
                          <option value={2}>
                            Professional Development Training
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-30">
                        <select
                          type="dropdown"
                          id="status"
                          name="status"
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option disabled hidden selected>
                            Company Status
                          </option>
                          <option value={0}>LLC</option>
                          <option value={1}>Partnership</option>
                          <option value={2}>Coportation</option>
                          <option value={3}>In-progress</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group textarea mb-30">
                        <textarea
                          id="message"
                          name="message"
                          placeholder="Write Message"
                          defaultValue={""}
                          onChange={this.handleInputChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 select">
                      <h5 className="title mb-30">Select any that apply</h5>
                    </div>
                    {[
                      "disabled_veteran",
                      "veteran_owned",
                      "8(a)_business",
                      "historically_underutilized_business",
                      "business_strategy",
                      "woman_owned_small_business",
                      "small_disadvantaged_business",
                      "minority_owned_small_business",
                    ].map((type) => (
                      <div key={`${type}`} className="col-12 mb-30 select">
                        <Form.Check
                          custom
                          checked={this.state[type]}
                          type="checkbox"
                          id={`${type}`}
                          onChange={this.handleInputChange.bind(this)}
                          label={`${type
                            .replaceAll("_", " ")
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter.toUpperCase()
                            )}`}
                        />
                      </div>
                    ))}
                    <div className="col-12 mb-30">
                      <p>
                        By clicking submit you agree to
                        <Link to="/terms" className="service-link">
                          &nbsp; Aglosi Inc’s Privacy Policy &nbsp;
                        </Link>
                        governing how your data is processed, and consent to
                        receiving our email communications based on the interest
                        you selected or contents downloaded.
                      </p>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="main-btn"
                        onClick={(e) => this.sendMessage(e)}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid container-1600">
          <div className="contact-map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53072.10393964216!2d-84.39211640044984!3d33.76049799533946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5045d6993098d%3A0x66fede2f990b630b!2sAtlanta%2C%20GA%2C%20USA!5e0!3m2!1sen!2s!4v1608941929746!5m2!1sen!2s"
              style={{ border: 0 }}
              allowFullScreen
              aria-hidden="false"
              tabIndex={0}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Content;
