import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

class faqs extends Component {
  render() {
    return (
      <section className="faq-section section-gap">
        <div className="container">
          <div className="row faq-page-title mb-60 align-items-center">
            <div className="col-lg-6">
              <div className="section-title left-border">
                <span className="title-tag">Terms of Use</span>
                <h2 className="title">Aglosi Inc. Privacy Practices</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <p>
                Below you will find Aglosi Inc.’s Privacy Policy, Cookie Policy.
                These policies apply to Aglosi Inc. and its affiliated companies
                (collectively “Aglosi”). PLEASE READ THESE POLICIES CAREFULLY.
              </p>
            </div>
            <div className="faq-section section-gap" />
            <div className="col-lg-12">
              <p>
                <i>Effective: Dec 30, 2020</i> Aglosi Inc. understands that your
                privacy is vital to you. Aglosi Inc. is steadfast in the
                protection of your confidentiality and privacy of personal
                information entrusted to it. This Privacy Policy describes how
                Aglosi Inc. handles and shields your personal data in connection
                with the hosting of its website
              </p>
            </div>
          </div>
          {/* FAQ LOOP */}
          <Accordion
            defaultActiveKey="0"
            className="faq-loop grey-header"
            id="faqAccordion"
          >
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  This policy applies to your personal information which we
                  collect and/or how it will be used by Aglosi Inc. The
                  circumstances in which this would occur can be viewed below in
                  section 5 entitled "Collecting personal information". When we
                  provide Services to clients, we sometimes handle client’s
                  personal information through our data platforms.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Scope of this policy
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="1" className="collapseparent">
                <Card.Body>
                  <p>
                    Aglosi collects personal or business information to provide
                    business matchmaking strategic fitness advisory services to
                    its clients. It also processes client personal information
                    to manage its relationship with those clients. Further,
                    Aglosi also collects some personal information from visitors
                    to this website, recruitment applicants, and attendees at
                    Aglosi events.
                  </p>
                  <p>
                    Aglosi uses personal information to deliver its Services to
                    clients. For its own purposes, it also us representatives of
                    its clients or prospective clients and to market to them,
                    and to administer recruitment and events.{" "}
                  </p>
                  <p>
                    If Aglosi uses your personal information, you may have
                    certain important rights which you can exercise. The rights
                    you will be able to exercise will depend on how and why
                    Aglosi uses your information.
                  </p>
                  <p>
                    {" "}
                    The primary point of contact at Aglosi for questions
                    regarding your personal information is support@aglosi.com
                    personal information to analyze and improve how it delivers
                    those services, to contacts.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Overview
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="2" className="collapseparent">
                <Card.Body>
                  In respect of personal information collected, your continued
                  use of the websites constitutes your understanding of, and
                  agreement to, the practices described in this Privacy Policy
                  and elsewhere on the websites as well as any subsequent
                  updates.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Your Acceptance
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="3" className="collapseparent">
                <Card.Body>
                  We collect information about you if:
                  <ul>
                    <li>you use this (or any other Aglosi) website;</li>
                    <li>
                      you inquire about, or engage Aglosi to provide, its
                      Services{" "}
                    </li>
                    <li>
                      the use of your personal information is reasonably
                      necessary to provide our Services (in these circumstances,
                      your personal information may be disclosed to us by our
                      client who may obtain your personal information from a
                      range of public or subscription sources, directly from
                      you, or from your associates or persons known to you);
                    </li>
                    <li>you apply for a position with Aglosi;</li>
                    <li>
                      you attend an Aglosi hosted or sponsored event or webinar;
                    </li>
                    <li>
                      you contact us with any other inquiry, complaint or
                      notice.
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  Collection of Personal Information
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="4" className="collapseparent">
                <Card.Body>
                  The following is a summary of the types of personal
                  information we collect, and the purposes for which that
                  information is used.
                  <ol>
                    <li>
                      Aglosi collects your name, address, e-mail address,
                      telephone number and any other personally-identifiable
                      information which you voluntarily provide as "comments"
                      when you submit an inquiry through the "Contact Us" tool.
                      Aglosi also collects other background information about
                      you in connection with career-related inquiries that you
                      submit through its website.
                    </li>
                    <li>
                      Aglosi may also automatically collect personal information
                      (through the use of Cookies or similar technologies,
                      including your IP address, device identifier, browsing
                      patterns on its websites, click stream data, and HTP
                      protocol elements) to ensure the effective operation of
                      the website or in order to align the presentation of our
                      Services more closely to your requirements (for example,
                      processing information about your broad geographic
                      location to serve you with a local version of this
                      website). This tracking information is stored in an
                      anonymous, aggregated and non-personal format, and is also
                      used to understand and analyze trends, to administer the
                      websites, and to learn about user behavior on the
                      websites.{" "}
                    </li>
                    <li>
                      Please refer to our Cookie Policy for a more detailed
                      overview on how Aglosi uses Cookies.
                    </li>
                    <li>
                      In certain instances, Aglosi may use IP addresses to help
                      identify you when Aglosi feels, in its sole discretion,
                      that it is necessary to enforce compliance with this
                      Privacy Policy, to protect its services, websites,
                      systems, information, employees, business partners,
                      subsidiaries, affiliates, users, customers or others, or
                      when required by law or for law enforcement purposes
                    </li>
                  </ol>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  What types of personal information are collected and what do
                  we use it for?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="5" className="collapseparent">
                <Card.Body>
                  <p>
                    If you submit an inquiry to Aglosi about our Services
                    (either over the website, or by emailing, telephoning or
                    meeting with one of our colleagues), then we will process
                    information such as your name, job title and contact
                    information in order to respond to your inquiry.
                  </p>
                  <p>
                    {" "}
                    If you attend an Aglosi event or webinar, or if you
                    associate with an Aglosi colleague at, for example, an
                    industry event, then Aglosi may collect basic personal
                    information, such as contact details, which you voluntarily
                    provide (for example, by filling in a form or handing over a
                    business card) in order to facilitate your participation in
                    the event, and for the management of our relationship with
                    you as an actual or prospective client.
                  </p>{" "}
                  <p>
                    If you or the organization you are associated with becomes
                    an Aglosi client, then we may process your personal
                    information in order to:
                  </p>
                  <ul>
                    <li>
                      carry out "Know Your Client" checks and screening prior to
                      starting a new engagement (as well as basic contact
                      information, this may mean processing compliance related
                      information such as proof of your identity, information
                      about your professional background, history of
                      directorships and, in some circumstance, details of any
                      criminal convictions or adverse media coverage);
                    </li>
                    <li>
                      carry out background checks for the purposes of complying
                      with anti-money laundering and terrorist financing laws;
                    </li>
                    <li>
                      carry out client communication, service, billing and
                      administration;
                    </li>
                    <li>deal with client complaints; and</li>
                    <li>administer claims.</li>
                  </ul>
                  <p>
                    Taking account of applicable marketing laws, we also process
                    personal information about our clients (former, current and
                    prospective) in order to:
                  </p>
                  <ul>
                    <li>
                      send our clients newsletters, know-how, promotional
                      material and other marketing communications;
                    </li>
                    <li>
                      invite our clients to events (and arrange and administer
                      those events).
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  Aglosi's Former, Current and Prospective Clients
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="6" className="collapseparent">
                <Card.Body>
                  As discussed above, many of our Services involve the
                  processing of personal information. In the majority of cases,
                  personal information is provided to us in strict confidence,
                  subject to restrictive undertakings on its use / disclosure.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  Performing Services for Our Clients
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="7" className="collapseparent">
                <Card.Body>
                  <p>
                    If you apply for a position with Aglosi we will need to
                    collect personal information in order to consider your
                    application, and during any interview and assessment phase.
                  </p>{" "}
                  <p>
                    If you inquire about Aglosi we will collect basic personal
                    information required to register you as a client.
                  </p>
                  <p>
                    Finally, if you contact us for any other reason, we will
                    collect basic contact details, as well as any other personal
                    information relevant to the reason for your enquiry, in
                    order to resolve that inquiry.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                  Other Potential Uses
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="8" className="collapseparent">
                <Card.Body>
                  All processing (i.e. use) of your personal information is
                  justified by a "lawful basis" for processing. In the majority
                  of cases, processing will be justified on the basis that:
                  <ul>
                    <li>
                      the processing is necessary for the performance of a
                      contract to which you are a party, or to take steps (at
                      your request) to enter into a contract (e.g. where you
                      request certain Services as an individual client, or where
                      we help advise your employer or service provider on
                      fulfilling an obligation to you under a contract);
                    </li>
                    <li>
                      the processing is necessary for us to comply with a
                      relevant legal obligation (e.g. where we are required to
                      collect certain information about our clients for tax or
                      accounting purposes, or where we are required to make
                      disclosures to courts or regulators); or
                    </li>
                    <li>
                      the processing is necessary for the performance of a task
                      carried out in the public interest (e.g. background checks
                      for anti-money laundering and terrorist financing
                      purposes); or
                    </li>
                    <li>
                      the processing is in our legitimate interests, subject to
                      due consideration for your interests and fundamental
                      rights (this is the basis we rely upon for the majority of
                      the processing of personal information in connection with
                      the provision of our Services, and also for the purposes
                      of most client on-boarding, administration and
                      relationship management activities).
                    </li>
                  </ul>
                  <p>
                    In limited circumstances, we will use your consent as the
                    basis for processing your personal information, for example,
                    where we are required by applicable law to obtain your prior
                    consent in order to send you marketing communications.
                  </p>
                  <p>
                    Before collecting and/or using any special categories of
                    data or criminal record data, we will establish a lawful
                    exemption which will allow us to use that information. This
                    exemption will typically be:
                  </p>
                  <ul>
                    <li>your explicit consent;</li>
                    <li>
                      the establishment, exercise or defense by us or third
                      parties of legal claims
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  What is our legal basis for collecting personal information?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="9" className="collapseparent">
                <Card.Body>
                  Aglosi will not disclose your personal information to third
                  parties other than as described in this Privacy Policy unless
                  Aglosi has your permission or is required or permitted by law.
                  Aglosi may share such information with its affiliates as
                  necessary to carry out the purposes for which the information
                  was supplied or collected. Similarly, third party contractors,
                  consultants and/or vendors engaged by Aglosi to provide
                  services may have access to your personal information. These
                  third parties will be subject to their own data protection
                  requirements providing the same or greater level of security
                  provided by Aglosi and in most instances will also have
                  entered into a written agreement with Aglosi which addresses
                  the protection of your personal information. Aglosi may also
                  disclose your personal information for the purposes of:
                  <ul>
                    <li>
                      responding to requests from law enforcement agencies,
                      regulators or courts, or to subpoenas, search warrants, or
                      other legal requests;
                    </li>
                    <li>the prevention and/or detection of crime;</li>
                    <li>
                      establishing legal rights or to investigate or pursue
                      legal claims;
                    </li>
                    <li>
                      a merger, acquisition or corporate restructuring to which
                      Aglosi is subject;{" "}
                    </li>
                    <li>preventing risk of harm to an individual.</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  Disclosure of Personal Information to Third Parties
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="9" className="collapseparent">
                <Card.Body>
                  By using Aglosi websites you consent to the collection, use
                  and storage of personal information as described in this
                  Privacy Policy and elsewhere on the websites. Aglosi reserves
                  the right to make changes to this Website Privacy Policy from
                  time to time. Aglosi will notify you by posting amendments to
                  the Privacy Policy on this website.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  Changes to this Policy
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="9" className="collapseparent">
                <Card.Body>
                  If you have questions or concerns regarding this policy or
                  Aglosi’s personal data processing policies, please contact
                  Aglosi at: support@aglosi.com.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  Contact Us
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
          </Accordion>
          <div className="row faq-page-title mb-60 align-items-center">
            <div className="col-lg-6">
              <div className="section-title left-border">
                <span className="title-tag">Terms of Use</span>
                <h2 className="title">Aglosi Inc. Cookie Policy</h2>
              </div>
            </div>
            <div className="faq-section section-gap" />
            <div className="col-lg-12">
              <p>
                <i>Effective Date: December 30, 2020.</i> Aglosi Inc.
                understands that your privacy is vital to you. Aglosi Inc. is
                steadfast in the protection of your confidentiality and privacy
                of personal information entrusted to it. This Privacy Policy
                describes how Aglosi Inc. handles and shields your personal data
                in connection with the hosting of its website
              </p>
            </div>
          </div>
          <Accordion
            defaultActiveKey="0"
            className="faq-loop grey-header"
            id="faqAccordion"
          >
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  A cookie is a small text file containing small amounts of
                  information that a website puts on a user’s computer (or
                  mobile device) when you visit a website and is used by the
                  website to send information to your browser and for the
                  browser to return information to the website. For a video
                  about cookies, please visit
                  www.google.com/policies/technologies/cookies. You can find
                  more information about cookies at: www.allaboutcookies.org.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  What are Cookies and other Tracking Technologies?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  The information your browser sends to one of Aglosi’s websites
                  is used for authentication and identification of your session
                  and allows Aglosi to display the correct information on the
                  website. Aglosi matches the cookie against your details so it
                  can distinguish you from other users as you browse the site,
                  which helps it to provide you with a good user experience.
                  Most web browsers enable you to erase cookies, block cookies,
                  receive a warning before a cookie is stored on your device or
                  disable cookies. If you wish to withdraw consent to use
                  Cookies at any point you will need to delete cookies using
                  your web browsers settings. If you do so, however, this site
                  may not function correctly. Logging: Aglosi records and stores
                  details of the pages visited so that it can generate usage
                  statistics. Except as otherwise provided in this Privacy
                  Policy, Aglosi does not sell or share any information gathered
                  to other companies. Cookie retention: This website uses
                  cookies which are persistent and non-persistent
                  (session-based). While many of the cookies used on this
                  website only last for the duration of your use of this website
                  (session-based cookies), other cookies are persistent, i.e.,
                  stay in one of your browser's subfolders until you delete them
                  manually or your browser deletes them.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  How do we use Cookies and other Tracking Technologies?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  First party cookies – First party cookies are set by the
                  Aglosi website you are visiting and they can only be read by
                  Aglosi. Session cookies – Session cookies are used by the
                  server to store information about user page activities so
                  users can easily pick up where they left off on the server's
                  pages. These are temporary cookie files, which are erased when
                  you close your browser, and when you restart your browser and
                  go back to the site that created the cookie, the website will
                  not recognize you. You will have to log back in (if login is
                  required) or select your preferences/themes again if the
                  website uses these features. A new session cookie will be
                  generated, which will store your browsing information and will
                  be active until you leave the site and close your browser.
                  Persistent cookies – Persistent cookies are employed to store
                  user preferences. These files stay in one of your browser's
                  subfolders until you delete them manually or your browser
                  deletes them based on the duration period contained within the
                  persistent cookie's file. Third party cookies – Third party
                  cookies are set by a different organization to the owner of
                  the website you are visiting. For example, the website might
                  use a third party analytics company that will set its own
                  cookies to perform this service (for example, as described
                  below, this website uses Google Analytics). Aglosi websites
                  also uses Google Analytics, a web analytics service provided
                  by Google, Inc. ("Google") to help analyze how users use the
                  site, for example which pages are most frequently visited. The
                  information generated by a Google Analytics cookie about your
                  use of the website (including your IP address) will be
                  transmitted to and stored by Google on servers in the United
                  States. Google will use this information for the purpose of
                  evaluating your use of the website, compiling reports on
                  website activity for website operators and providing other
                  services relating to website activity and internet usage.
                  Google may also transfer this information to third parties
                  where required to do so by law, or where such third parties
                  process the information on Google's behalf. Google will not
                  associate your IP address with any other data held by Google.
                  By using this website, you consent to the processing of data
                  about you by Google in the manner and for the purposes set out
                  above. By continuing to use this website you give your consent
                  for Aglosi to use cookies as detailed above. If the way Aglosi
                  uses cookies changes significantly (e.g. a new cookie being
                  added with different functionality), Aglosi will inform you
                  from the log in page. This detail will always be available to
                  you from the navigation links at the bottom of all pages.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  What types of Cookies may be used?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="0" className="collapseparent">
                <Card.Body>
                  By using Aglosi’s website you agree that Aglosi can place
                  cookies on your device as explained above. If you want to
                  remove existing cookies from your device you can do this using
                  your browser options. If you want to block future cookies from
                  being placed on your device you can change your browser
                  settings to do this. Please note that if you use your browser
                  settings to block the use of all Cookies it may impact certain
                  functionality on the Aglosi websites or degrade your browsing
                  experience.
                </Card.Body>
              </Accordion.Collapse>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  What if I don’t want cookies to be used?
                  <span className="icons">
                    <i className="far fa-plus" />
                  </span>
                </Accordion.Toggle>
              </Card.Header>
            </Card>
          </Accordion>
        </div>
      </section>
    );
  }
}

export default faqs;
