import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";

import img from "../../../../assets/img/tab-block.jpg";

const missiontab = [
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
];
const historytab = [
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
];
const businessgoalstab = [
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
];
const teammembertab = [
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
  {
    icon: "fas fa-check",
    text:
      "There are many variations of passages of LoreIpsum available, but the majority have suffered",
  },
];

class Advancedtab extends Component {
  render() {
    return (
      <section className="advanced-tab section-gap">
        <div className="container">
          {/* Tabs Buttons */}
          <div className="tab-buttons">
            <Tab.Container defaultActiveKey="mission">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="mission">
                    Our Mission &amp; Vision
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history">Company History</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="businessgoals">Business Goals</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="teammember">Team Member</Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
        </div>
      </section>
    );
  }
}

export default Advancedtab;
