import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "@formspree/react";
import toast from "react-hot-toast";

import logo from "../../../assets/img/logo-2.png";
import shape1 from "../../../assets/img/lines/01.png";
import shape2 from "../../../assets/img/lines/02.png";

import img1 from "../../../assets/img/instagram/01.jpg";
import img2 from "../../../assets/img/instagram/02.jpg";
import img3 from "../../../assets/img/instagram/03.jpg";
import img4 from "../../../assets/img/instagram/04.jpg";
import img5 from "../../../assets/img/instagram/05.jpg";
import img6 from "../../../assets/img/instagram/06.jpg";
const instapost = [
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
  { img: img5 },
  { img: img6 },
];
const Footer = () => {
  const [state, handleSubmit] = useForm("subscribeForm");
  if (state.succeeded) {
    toast.success("Thank you! We will be in touch shortly");
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer>
      <div className="container">
        <div className="footer-widget">
          <div className="row">
            <div className="col-lg-4 col-sm-5 order-1">
              <div className="widget site-info-widget">
                <div className="footer-logo">
                  <img src={logo} alt="" />
                </div>
                <p>Your Vision is Our Mission.</p>
                <ul className="social-links">
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-sm-7 order-2">
              <div className="widget newsletter-widget">
                <h4 className="widget-title">Subscribe To Our Mailing List</h4>
                <div className="newsletter-form">
                  <form onSubmit={handleSubmit}>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                    />
                    <button
                      type="submit"
                      className="main-btn"
                      disabled={state.submitting}
                    >
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-5 order-lg-4 order-5">
              <div className="row">
                <div className="col-lg-8 col-sm-8">
                  <div className="widget contact-widget">
                    <ul className="contact-infos">
                      <li>
                        <i className="far fa-phone" />
                        +012 (345) 6789
                      </li>
                      <li>
                        <Link to="mailto:aglosi@aglosi.com">
                          <i className="far fa-envelope-open" />
                          aglosi@aglosi.com
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <i className="far fa-map-marker-alt" /> 2020 Howell Mill
                        Rd NE, Stec.203, Atlanta GA, 30318
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p className="copyright-text">
            <span>
              @<Link to="#">Aglosi Inc.</Link> 2020
            </span>
            <span>All Right Reserved By Aglosi Inc.</span>
          </p>
          <Link
            to="#"
            className="back-to-top"
            onClick={() => this.scrollToTop()}
          >
            <i className="far fa-angle-up" />
          </Link>
        </div>
      </div>
      {/* Lines */}
      <img src={shape1} alt="line" className="line-one" />
      <img src={shape2} alt="line" className="line-two" />
    </footer>
  );
};

export default Footer;
