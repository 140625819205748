import React, { Component } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

class Skills extends Component {
  render() {
    return (
      <section className="skill-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-10">
              {/* Skill Text Block */}
              <div className="skill-text">
                <div className="section-title mb-40 left-border">
                  <span className="title-tag">Our Experience</span>
                  <h2 className="title">
                    We Have Experience <br /> Across Diverse Industries
                  </h2>
                </div>
                <p>
                  As business face challenges with slow growth, management, the
                  impact of disruptive technologies and unforeseen competitive
                  threats, Aglosi Inc. brings proven expertise and experience to
                  help clients win in their market.
                </p>
                <p>
                  Clients rely on us to define clear, practicable solutions for
                  their challenges and opportunities while seeking our support
                  during their transition, ensuring they have the relevant
                  capability and commitment to change.
                </p>
                <Link to="/about" className="main-btn">
                  Learn More
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="piechart-boxes">
                <div className="chart-box">
                  <div className="chart">
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? 25 : 0;
                        return (
                          <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#87c287",
                              trailColor: "#e8e8e8",
                              backgroundColor: "#f5f5f5",
                            })}
                          />
                        );
                      }}
                    </VisibilitySensor>
                    <i className="flaticon-presentation" />
                  </div>
                  <h4 className="title">Business Strategy</h4>
                </div>
                <div className="chart-box">
                  <div className="chart">
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? 40 : 0;
                        return (
                          <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#87c287",
                              trailColor: "#e8e8e8",
                              backgroundColor: "#f5f5f5",
                            })}
                          />
                        );
                      }}
                    </VisibilitySensor>
                    <i className="flaticon-money-bags" />
                  </div>
                  <h4 className="title">Growth Strategy</h4>
                </div>
                <div className="chart-box">
                  <div className="chart">
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? 75 : 0;
                        return (
                          <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#87c287",
                              trailColor: "#e8e8e8",
                              backgroundColor: "#f5f5f5",
                            })}
                          />
                        );
                      }}
                    </VisibilitySensor>
                    <i className="flaticon-invest" />
                  </div>
                  <h4 className="title">Idea Creation</h4>
                </div>
                <div className="chart-box">
                  <div className="chart">
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible ? 80 : 0;
                        return (
                          <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#87c287",
                              trailColor: "#e8e8e8",
                              backgroundColor: "#f5f5f5",
                            })}
                          />
                        );
                      }}
                    </VisibilitySensor>
                    <i className="flaticon-connector" />
                  </div>
                  <h4 className="title">Personalized Coaching</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
