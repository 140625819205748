import React, { Component } from "react";
import Slider from "react-slick";

import testimonial from "../../../../assets/img/testimonial/testimonial.jpg";
import testimonialauthor from "../../../../assets/img/testimonial/author.png";

const testimonialslide = [
  {
    img: testimonialauthor,
    name: "Jeannine Jean-Pierre",
    post: "CEO & Founder",
    comment:
      "My passion is to understand my clients' unique problems and use my experience to arrive at the ideal solution.",
  },
  {
    img: testimonialauthor,
    name: "Jeannine Jean-Pierre",
    post: "CEO & Founder",
    comment:
      "My passion has always been to empowering my fellow veterans achieve their professional potential.",
  },
  {
    img: testimonialauthor,
    name: "Jeannine Jean-Pierre",
    post: "CEO & Founder",
    comment:
      "Seeing my fellow veterans command their future gives me limitless conviction and hope.",
  },
];
class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      arrows: true,
      fade: false,
      dots: false,
      swipe: true,
    };
    return (
      <section className="testimonial-section grey-bg">
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 col-md-10 offset-lg-5">
              <div className="section-title left-border">
                <span className="title-tag">Meet Our Team</span>
                <h2 className="title">
                  Woman-Owned <br /> Army Veteran
                </h2>
              </div>
              <div className="feature-desc">
                <h4>Over 17 years of diverse management experiences.</h4>
                <br />
                <p>
                  Aglosi’s President & CEO has multi-industry experience
                  implementing projects in IS / IT, healthcare, construction,
                  disaster recovery, telecommunications & business continuity.
                </p>
                <br />
                <p>
                  A United States Army veteran, All-American on the United
                  States basketball team, United States SDVOSB Army volleyball
                  player, and Hall of Fame inductee
                </p>
                <br />
                <p>
                  Served as PMP Exam Committee Chair, Professional Development
                  Chair, Project Management Institute-Community of Practice 1 of
                  5 team leads for 17K members, PMI Global Congress Subject
                  Matter Expert (SME), PMI–IT & Telecom–Special Interest Group.
                </p>
                <br />
                <p>
                  Toastmasters Vice President of Education and club President.
                  Appointed by district govenore as the District Process Officer
                  for Georgia.
                </p>
                <br />
                <p>
                  Master’s Degree in Technology from Georgia Institute of
                  Technology, she became a Certified Life Coach and a Certified
                  Leadership and Communications Toastmaster
                </p>
              </div>
              <Slider
                className="testimonial-items"
                ref={(c) => (this.slider = c)}
                id="testimonialSliderOne"
                {...settings}
              >
                {testimonialslide.map((item, i) => (
                  <div key={i} className="testimonial-item">
                    <div className="content">
                      <p>
                        <span className="quote-top">
                          <i className="fas fa-quote-left" />
                        </span>
                        {item.comment}
                        <span className="quote-bottom">
                          <i className="fas fa-quote-right" />
                        </span>
                      </p>
                    </div>
                    <div className="author">
                      <div className="desc">
                        <h4>{item.name}</h4>
                        <span>{item.post}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="testimonial-arrows row">
                <div className="col-12 order-1">
                  <button
                    className="slick-arrow next-arrow"
                    onClick={this.next}
                  >
                    <i className="fal fa-long-arrow-right" />
                  </button>
                </div>
                <div className="col-12 order-2">
                  <button
                    className="slick-arrow prev-arrow"
                    onClick={this.previous}
                  >
                    <i className="fal fa-long-arrow-left" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonials img */}
        <div className="testimonial-img">
          <img src={testimonial} alt="" />
        </div>
      </section>
    );
  }
}

export default Testimonials;
