import React, { Component } from "react";
import { Link } from "react-router-dom";

import illustration from "../../../../assets/img/illustration/01.png";

class About extends Component {
  render() {
    return (
      <section className="about-section about-illustration-img section-gap">
        <div className="container">
          <div className="illustration-img">
            <img src={illustration} alt="" />
          </div>
          <div className="row no-gutters justify-content-lg-end justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="about-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">About Us</span>
                  <h2 className="title">
                    Professional Business <br /> Guidance Agency{" "}
                  </h2>
                </div>
                <p className="mb-25">
                  We don’t believe in the adage that “one hat fits all”. Our
                  business matchmaking solutions incorporate customized
                  strategic methodologies designed specifically for each client.
                  We listen, gather data, and analyze in order to deliver the
                  best value for our clients. We are a Woman Owned, Service
                  Disabled Veteran firm that takes a unique approach to
                  conquering your unique challenges.
                </p>
                <p>Our industry experience includes:</p>
                <ul className="about-list">
                  <li>
                    {" "}
                    <i className="far fa-check" /> Digital Integration – Effects
                    of Technology and Human Interface
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Solving Common Leadership
                    Challenges
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> IT and Telecom - Business
                    Continuity
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Managing Stress in a
                    Stressful Environment
                  </li>
                  <li>
                    {" "}
                    <i className="far fa-check" /> Wellness and Team Building
                    for the Competitors
                  </li>
                </ul>
                <Link to="/contact" className="main-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
