import React from "react";

import Error from "../misc/Error";
import deleteSubscription from "../../actions/deleteSubscription";
import FireflySubscription from "../misc/FireflySubscription";

class Subscription extends React.Component {
  // Careful, this is the legacy checkout
  // https://stripe.com/docs/legacy-checkout#integration-custom

  // New stripe checkout here:
  // https://stripe.com/docs/payments/checkout#integration-custom

  componentDidMount() {}

  newSubscription = () => {};

  updatePaymentMethod = () => {};

  render() {
    return (
      <FireflySubscription>
        {({ error, isLoading, subscription }) => {
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return <p>loading...</p>;
          }

          if (!subscription) {
            return (
              <div>
                <p>
                  <strong>Subscribe to get paid features</strong>
                </p>
                <p>
                  Use Stripe test card number{" "}
                  <span style={{ fontFamily: "monospace" }}>
                    4242 4242 4242 4242
                  </span>
                  , any pin, and any future expiration date
                </p>
                <button onClick={this.newSubscription}>Subscribe now</button>
              </div>
            );
          }

          if (subscription.stripeSubscriptionError) {
            return (
              <div style={{ color: "red" }}>
                <p>
                  Whoops&mdash;there was an error updating your subscription.
                </p>
                <p style={{ fontFamily: "monospace" }}>
                  {subscription.stripeSubscriptionError}
                </p>
                <button onClick={() => deleteSubscription(subscription)}>
                  Delete and try again
                </button>
              </div>
            );
          }

          if (subscription.tempStripePaymentTokenId) {
            return <p>Updating your subscription...</p>;
          }

          const cancelConfirmation = `Are you sure you want to cancel your subscription? You won't have access to paid Firefly features.`;

          return (
            <div>
              <p>You are subscribed!</p>
              <p>Status: {subscription.stripeSubscriptionStatus}</p>
              <button onClick={() => this.updatePaymentMethod(subscription.id)}>
                Update payment method
              </button>
              <button
                onClick={() => {
                  if (window.confirm(cancelConfirmation)) {
                    deleteSubscription(subscription);
                  }
                }}
              >
                Cancel subscription
              </button>
            </div>
          );
        }}
      </FireflySubscription>
    );
  }
}

export default Subscription;
