import React, { Component } from "react";
import { Link } from "react-router-dom";

import line1 from "../../../../assets/img/lines/11.png";
import line2 from "../../../../assets/img/lines/12.png";

import icon1 from "../../../../assets/img/icons/01.png";
import icon2 from "../../../../assets/img/icons/02.png";
import icon3 from "../../../../assets/img/icons/03.png";
import icon4 from "../../../../assets/img/icons/04.png";

const serviceblock = [
  {
    icon: icon1,
    title: "Business Matchmaking Fitness",
    text:
      "Troubled projects, project conception, telecom, and project planning",
  },
  {
    icon: icon2,
    title: "Life Coaching Services",
    text: "Executive and life coaching, leadership and management training",
  },
  {
    icon: icon3,
    title: "Professional Development Training",
    text:
      "In-person and online instructor-led training courses, workshops and seminars",
  },
];
class Service extends Component {
  render() {
    return (
      <section className="service-section grey-bg service-line-shape section-gap">
        <div className="container">
          {/* Section Title */}
          <div className="section-title text-center both-border mb-50">
            <span className="title-tag">Our Services</span>
            <h2 className="title">
              We Provide Exclusive Consulting <br /> and Coaching Expertise
            </h2>
          </div>
          {/* Services Boxes */}
          <div className="row service-boxes justify-content-center">
            {serviceblock.map((item, i) => (
              <div
                key={i}
                className="col-lg-3 col-sm-6 col-10 wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="700ms"
              >
                <div className="service-box-three border-0">
                  <div className="icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <h3>
                    <Link to="/contact">{item.title}</Link>
                  </h3>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="line-one">
          <img src={line2} alt="line-shape" />
        </div>
        <div className="line-two">
          <img src={line1} alt="line-shape" />
        </div>
      </section>
    );
  }
}

export default Service;
